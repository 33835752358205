import $ from 'jquery';

// ga('send', 'event', [eventCategory], [eventAction], [eventLabel], [eventValue], [fieldsObject]);

$(() => {
  $(document).on('click', '.ga-sign-in-link', () =>
    ga('send', 'event', 'buttons', 'click', 'Main navigation sign in'),
  );

  $(document).on('click', '.ga-trial-button.top', () =>
    ga('send', 'event', 'button', 'click', 'Home page hero signup'),
  );

  $(document).on('click', '.ga-trial-button.bottom', () =>
    ga('send', 'event', 'button', 'click', 'Home page secondary signup'),
  );

  $(document).on('click', '.ga-pricing-signup-button', () =>
    ga('send', 'event', 'button', 'click', 'Pricing page signup'),
  );
});
